import classNames from "classnames";
import React from "react";

import { ThemeProvider } from "foundation/Theme";

import styles from "./Modal.module.scss";

interface Props {
  children: React.ReactNode;
}

export function Modal({ children }: Props) {
  // useEffect(() => {
  //   if (children) {
  //     document.body.style.overflow = 'hidden';
  //   } else {
  //     document.body.style.overflow = 'unset';
  //   }
  // }, [children]);

  if (!children) {
    return null;
  }

  return (
    <div className={classNames(styles.Container, { [styles.Open]: children != null })}>
      <div className={styles.Background}></div>
      <div className={styles.Modal}>{children}</div>
    </div>
  );
}
