import React from "react";
import classNames from "classnames";

import { CloseIcon } from "assets";

import styles from "./Notification.module.scss";

export type Severity = "error" | "warning" | "info" | "success";

interface Props {
  children: React.ReactNode | React.ReactNode[];
  hidden: boolean;
  severity?: Severity;
  closable?: boolean;
  onClose?: () => void;
}

export function Notification({ children, hidden, onClose, closable, severity = "info" }: Props) {
  return (
    <div className={classNames(styles.Container, { [styles.Hidden]: hidden })}>
      <div className={classNames(styles.MessageContainer, styles[`Severity__${severity}`])}>
        <div className={styles.Message}>{children}</div>
        {closable && (
          <div className={classNames(styles.Close)} onClick={onClose}>
            <CloseIcon />
          </div>
        )}
      </div>
    </div>
  );
}
