import Bugsnag from "@bugsnag/js";
import type { AppProps } from "next/app";
import React from "react";

import "../styles/globals.scss";

import { ModalProvider } from "components/Modal";
import { NotificationProvider } from "components/Notification";
import { UserProvider } from "foundation/UserProvider/UserProvider";
import setupAxiosInterceptors from "src/utils/tokenInterceptor";

import "src/init-bugsnag";

setupAxiosInterceptors();

const ErrorBoundary = typeof window !== "undefined" ? Bugsnag.getPlugin("react")?.createErrorBoundary(React) : null;

export default function App({ Component, pageProps }: AppProps) {
  const app = (
    <NotificationProvider>
      <ModalProvider>
        <UserProvider user={pageProps.user || null}>
          <Component {...pageProps} />
        </UserProvider>
      </ModalProvider>
    </NotificationProvider>
  );

  if (ErrorBoundary) {
    return <ErrorBoundary>{app}</ErrorBoundary>;
  }

  return app;
}
