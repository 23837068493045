import React, { useState } from "react";

import { Modal } from "./Modal";

interface Props {
  children: React.ReactNode;
}

type ModalContextValue = {
  show: (modal: React.ReactNode) => void;
  close: () => void;
};

export const ModalContext = React.createContext<ModalContextValue>({
  show: () => {},
  close: () => {},
});

export function ModalProvider({ children }: Props) {
  const [modal, setModal] = useState<React.ReactNode>(null);

  const show = (modal: React.ReactNode) => {
    setModal(modal);
  };

  const close = () => {
    setModal(null);
  };

  return (
    <ModalContext.Provider value={{ show, close }}>
      <Modal>{modal}</Modal>
      {children}
    </ModalContext.Provider>
  );
}
