import React, { useEffect, useState } from "react";

import { Notification, Severity } from "./Notification";

interface Props {
  children: React.ReactNode;
}

type MessageProps = {
  message: React.ReactNode;
  severity?: Severity;
  hideAfter?: number;
};

type NotificationValue = {
  showMessage: (props: MessageProps) => void;
};

export const NotificationContext = React.createContext<NotificationValue>({ showMessage: () => {} });

export function NotificationProvider({ children }: Props) {
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();
  const [severity, setSeverity] = useState<"error" | "success" | "warning" | "info">("info");
  const [message, setMessage] = useState<React.ReactNode>(null);
  const [hidden, setHidden] = useState(true);
  const [cloasable, setClosable] = useState(false);

  useEffect(() => {
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  });

  const close = () => {
    setHidden(true);
    const id = setTimeout(() => setMessage(null), 500);
    setTimeoutId(id);
  };

  const onClose = () => {
    close();
  };

  const showMessage = ({ message, severity = "info", hideAfter }: MessageProps) => {
    setSeverity(severity);
    setMessage(message);
    setClosable(hideAfter == null);
    setHidden(false);

    if (hideAfter != null) {
      const id = setTimeout(() => {
        close();
      }, hideAfter);

      setTimeoutId(id);
    }
  };

  return (
    <NotificationContext.Provider value={{ showMessage }}>
      <Notification severity={severity} hidden={hidden} closable={cloasable} onClose={onClose}>
        {message}
      </Notification>
      {children}
    </NotificationContext.Provider>
  );
}
