import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

if (process.env.NODE_ENV !== "development" && typeof window !== "undefined") {
  Bugsnag.start({
    apiKey: process.env.NEXT_PUBLIC_BUGSNAG_KEY || "",
    plugins: [new BugsnagPluginReact()],
  });

  import("@bugsnag/browser-performance")
    .then((BugsnagPerformance: any) => {
      BugsnagPerformance.start({ apiKey: process.env.NEXT_PUBLIC_BUGSNAG_KEY || "" });
    })
    .catch((err) => console.error("Failed to load Bugsnag Performance", err));
}

if (process.env.NODE_ENV === "development" && typeof window !== "undefined") {
  Bugsnag.start({
    apiKey: process.env.NEXT_PUBLIC_BUGSNAG_KEY || "",
    plugins: [new BugsnagPluginReact()],
    logger: {
      debug: console.debug,
      info: console.info,
      warn: console.warn,
      error: console.error,
    },
    onError: function (event) {
      console.error("Bugsnag error:", event.errors[0]);
    },
  });
  console.log("Bugsnag is disabled in development mode");
}
