import React, { createContext, useContext } from "react";

interface User {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
}

interface Props {
  children: React.ReactNode | React.ReactNode[];
  user: User | null;
}

export const UserContext = createContext<User | null>(null);

export const UserProvider = ({ children, user }: Props) => {
  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
};

export function useUser() {
  const context = useContext(UserContext);

  if (context === undefined) {
    throw new Error("useUser must be used within a UserProvider");
  }

  return context;
}
